<template>
  <div class="data">
    <div class="banner">
      <div class="banner_text">
        <h6 class="banner_title">临床数据管理</h6>
        <div class="line"></div>
        <p class="banner_content">CLINICAL DATA MANAGEMENT</p>
      </div>
    </div>
    <div class="data_con">
      <div>
        <p class="title">临床研究支持</p>
        <div class="container data_title">
          <div class="data_content" v-for="(item, index) in dataList" :key="index">
            {{ item.title }}
          </div>
          <!-- <div class="data_content">
          临床调查问卷设计
        </div>
        <div class="data_content">
          临床研究注册
        </div>
        <div class="data_content">
          驻点CRC
        </div>
        <div class="data_content">
          数据挖掘与项目管理
        </div>
        <div class="data_content">
          远程数据录入及处理
        </div> -->
        </div>
      </div>
      <div>
        <p class="title">临床数据挖掘、算法与建模</p>
        <div class="container container_content">
          <div class="content">
            <span class="div-box"></span>
            <div class="box">与临床医生一起，筛选变量，分析与建立相关疾病风险模型，为疾病的发生、发展、治疗反应性或转归提供统计分析，建立合适的疾病预测模型。</div>
          </div>
          <div class="content">
            <span class="div-box1"></span>
            <div class="box">基于BERT、BI-LSTM+CRF等深度学习NLP技术助力非结构化特征结构化(如现病史、既往史中提取既往疾病、特征NIHSS评分等信息)， 丰富特征维度，提升模型的表现</div>
          </div>
          <div class="content">
            <span class="div-box2"></span>
            <div class="box">采用ARIMA、prophet、Catboost、LGM、DNN、XGB、RF等成熟主流机器学习方法，打破了传统统计学只有逻辑回归的痛点，提升了预测效果。</div>
          </div>
          <div class="content">
            <span class="div-box3"></span>
            <div class="box">针对临床上疾病相关数据，可以对数据进行结构化整理</div>
          </div>
        </div>


      </div>
      <div class="img">
        <img class="pc-img" src="../assets/dataImages/Data-pc1.jpg" alt="">
        <img class="mobile-img" src="../assets/dataImages/Data-mobile1.jpg" alt="">
      </div>
      <div class="img">
        <img class="pc-img" src="../assets/dataImages/Data-pc2.jpg" alt="">
        <img class="mobile-img" src="../assets/dataImages/Data-mobile2.jpg" alt="">
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList: [
        { title: "临床调查问卷设计" },
        { title: "临床研究注册" },
        { title: "驻点CRC" },
        { title: "数据挖掘与项目管理" },
        { title: "远程数据录入及处理" },
      ],
      contentList: [
        { title: '与临床医生一起，筛选变量，分析与建立相关疾病风险模型，为疾病的发生、发展、治疗反应性或转归提供统计分析，建立合适的疾病预测模型。' },
        { title: '基于BERT、BI-LSTM+CRF等深度学习NLP技术助力非结构化特征结构化(如现病史、既往史中提取既往疾病、特征NIHSS评分等信息)， 丰富特征维度，提升模型的表现' },
        { title: '采用ARIMA、prophet、Catboost、LGM、DNN、XGB、RF等成熟主流机器学习方法，打破了传统统计学只有逻辑回归的痛点，提升了预测效果。' },
        { title: '针对临床上疾病相关数据，可以对数据进行结构化整理' },
      ],

      methods: {
        addTouchEffect() {
          const dataContents = document.querySelectorAll('.data_content');
          dataContents.forEach(dataContent => {
            dataContent.addEventListener('touchstart', function () {
              this.style.backgroundColor = '#077dfb';
              this.style.color = '#fff';
            });
            dataContent.addEventListener('touchend', function () {
              this.style.backgroundColor = '';
              this.style.color = '';
            });
          });
        }
      },
      // 在mounted钩子中调用addTouchEffect()方法以确保组件加载后生效
      mounted() {
        this.addTouchEffect();
      }
    }

  },
}


</script>
<style scoped lang="less">
/* 公共*/


/*移动端*/
@media screen and (max-width: 767px) {
  .banner {
    width: 100%;
    height: 320px;
    background-image: url(../assets/dataImages/Data-banner2mobile.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .banner_text {
      width: 385px;
      height: 200px;
      text-align: center;

      .banner_title {
        font-size: 48px;
      }

      .banner_content {
        font-size: 20px;
        color: #333333
      }

      .line {
        position: relative;
        top: 2%;
        left: 44%;
        width: 30px;
        height: 2px;
        border-radius: 0;
        opacity: 1;
        border: 1px solid black;
        margin: 24px 0;
      }
    }
  }

  .pc-img {
    display: none;
  }

  .mobile-img {
    display: block;
    width: 361px;
    margin: auto;
  }

  /* 临床研究支持样式 */
  .title {
    font-size: 28px;
    color: #077dfb;
    text-align: center;
    margin-top: 40px;
  }

  container {
    display: block;
  }

  .data_content {
    width: 100%;
    height: 60px;
    text-align: center;
    line-height: 60px;
    background-color: #F9F9F9;
    margin: 5px 0;
  }

  .data_content:hover {
    background-color: #0f94ff;
    color: #fff;
  }

  .container_content {
    display: flex;
    flex-wrap: wrap;

    .content {
      width: 45%;
      height: 432px;
      text-align: center;
      box-shadow: 5px 5px 5px 5px rgba(171, 169, 169, 0.3);
      margin: 10px 8px;
      background-color: #fff;

      .box {
        margin-top: 90px;
      }
    }

    .content:hover {
      cursor: pointer;
    }

    .content:hover .div-box {
      background: url('../assets/dataImages/css_sprites.png') -149px -10px;
    }

    .content:hover .div-box1 {
      background: url('../assets/dataImages/css_sprites2.png') -149px -10px;
    }

    .content:hover .div-box2 {
      background: url('../assets/dataImages/css_sprites3.png') -149px -10px;
    }

    .content:hover .div-box3 {
      background: url('../assets/dataImages/css_sprites4.png') -149px -10px;
    }

    .div-box,
    .div-box1,
    .div-box2,
    .div-box3 {
      display: inline-block;
      border: 1px dashed gainsboro;
      width: 119px;
      height: 119px;
      background-repeat: no-repeat;
    }

    .div-box {
      background: url('../assets/dataImages/css_sprites.png') -10px -10px;
    }

    .div-box1 {
      background: url('../assets/dataImages/css_sprites2.png') -10px -10px;
    }

    .div-box2 {
      background: url('../assets/dataImages/css_sprites3.png') -10px -10px;
    }

    .div-box3 {
      background: url('../assets/dataImages/css_sprites4.png') -10px -10px;
    }
  }

}

/* PC端 */
@media screen and (min-width: 768px) {
  .data {
    height: 3310px;

    .banner {
      width: 100%;
      height: 600px;
      background-image: url(../assets/dataImages/Data-banner2.jpg);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      .banner_text {
        width: 385px;
        height: 200px;
        text-align: center;

        .banner_title {
          font-size: 48px;
        }

        .banner_content {
          font-size: 20px;
          color: #333333;
        }

        .line {
          position: relative;
          top: 2%;
          left: 44%;
          width: 30px;
          height: 2px;
          border-radius: 0;
          opacity: 1;
          border: 1px solid black;
          margin: 24px 0;
        }
      }
    }


    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      .pc-img {
        display: block;
        /* 默认显示PC图片 */
        width: auto;
      }

      .mobile-img {
        display: none;
        /* 默认隐藏移动端图片 */

      }
    }

    .data_con {
      height: 800px;

      .title {
        font-size: 30px;
        color: #010101;
        text-align: center;
        margin-top: 40px;
      }

      .data_title {
        display: flex;

        .data_content {
          width: 267px;
          height: 144px;
          text-align: center;
          line-height: 144px;
          background-color: #F9F9F9;
          margin: 0 5px;
        }

        .data_content:hover {
          background-color: #0f94ff;
          color: #fff;
        }
      }

      .container_content {
        display: flex;

        .content {
          width: 329px;
          height: 437px;
          text-align: center;
          box-shadow: 5px 5px 5px 5px rgba(171, 169, 169, 0.3);
          margin: 0 8px;
          background-color: #fff;

          .box {
            margin-top: 110px;
          }
        }

        .content:hover {
          cursor: pointer;
        }

        .content:hover .div-box {
          background: url('../assets/dataImages/css_sprites.png') -149px -10px;
        }

        .content:hover .div-box1 {
          background: url('../assets/dataImages/css_sprites2.png') -149px -10px;
        }

        .content:hover .div-box2 {
          background: url('../assets/dataImages/css_sprites3.png') -149px -10px;
        }

        .content:hover .div-box3 {
          background: url('../assets/dataImages/css_sprites4.png') -149px -10px;
        }

        .div-box,
        .div-box1,
        .div-box2,
        .div-box3 {
          display: inline-block;
          border: 1px dashed gainsboro;
          width: 119px;
          height: 119px;
          background-repeat: no-repeat;
        }

        .div-box {
          background: url('../assets/dataImages/css_sprites.png') -10px -10px;
        }

        .div-box1 {
          background: url('../assets/dataImages/css_sprites2.png') -10px -10px;
        }

        .div-box2 {
          background: url('../assets/dataImages/css_sprites3.png') -10px -10px;
        }

        .div-box3 {
          background: url('../assets/dataImages/css_sprites4.png') -10px -10px;
        }
      }
    }

    .img {
      height: 953px;
    }
  }
}
</style >

  